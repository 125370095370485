import Home from 'pages/Home/HomePage';
import PeoplePage from 'pages/People/index';
import ProfileSettings from 'pages/profileSettings/ProfileSettings';
import SettingsPage from 'pages/Settings/SettingsPage';
import Supplies from 'pages/Supplies/Supplies';
import RemoteKioskPage from 'pages/RemoteKiosk/RemoteKioskPage';
import { KioskPage } from 'pages/Kiosk/KioskPage';
import Worklogs from 'pages/Worklogs/index';
import SchedulePage from 'pages/Schedule/index';
import Projects from 'pages/Projects';
import MySchedule from 'pages/WorkerSchedule/index';
import Cip from 'pages/CIP/CIPPage';
import TimeClock from 'pages/TimeClock/TimeClockPage';
import EquipmentPage from 'pages/Equipment/index';
import NotesPage from 'pages/Notes/index';
import ProjectAndWorklogNotes from 'pages/NotesByProject/index';
import AvailabilityWrapper from '../../pages/Availability/index';
import { permissions as allPermissions } from 'api/users/users.permissions';
import AvailabilityPrintWrapper from '../../pages/AvailabilityPrint/index';
import SuperAdmin from 'pages/SuperAdmin/SuperAdmin';
import { MobileKiosk } from '../../pages/MobileKiosk/MobileKiosk';
import Contractors from 'pages/Contractors/Contractors';
import WorkLogPdf from 'pages/WorkLogPdf/WorkLogPdf';

const routes = [
  {
    path: 'home',
    redirectPath: null,
    children() {
      return <Home />;
    },
    permissions: Object.keys(allPermissions.All),
  },
  {
    path: 'projects',
    redirectPath: null,
    children() {
      return <Projects />;
    },
    permissions: ['projectsRead', 'projectsFullAccess', 'projectsEdit'],
  },
  {
    path: 'contractors',
    redirectPath: null,
    children() {
      return <Contractors />;
    },
    permissions: ['projectsRead', 'projectsFullAccess', 'projectsEdit'],
  },
  {
    path: 'profile-settings',
    redirectPath: null,
    children() {
      return <ProfileSettings />;
    },
    permissions: Object.keys(allPermissions.All),
  },
  {
    path: 'notes',
    redirectPath: null,
    children() {
      return <NotesPage />;
    },
    permissions: ['notesRead', 'notesFullAccess'],
  },
  {
    path: 'notes/:projectId',
    redirectPath: null,
    children() {
      return <ProjectAndWorklogNotes />;
    },
    permissions: ['notesRead', 'notesFullAccess'],
  },
  {
    path: 'schedule-user',
    redirectPath: null,
    children() {
      return <MySchedule />;
    },
    permissions: ['assignedSchedule'],
  },
  {
    path: 'schedule-user/:sheetId',
    redirectPath: null,
    children() {
      return <MySchedule />;
    },
    permissions: ['assignedSchedule'],
  },
  {
    path: 'remote-kiosk',
    redirectPath: null,
    children() {
      return <RemoteKioskPage />;
    },
    permissions: [],
    role: [
      'Admin',
      'Field Technician',
      'Accounting',
      'Fleet Maintenance',
      'Project Management',
      'Dispatcher',
      'Machine Shop',
      'Super Admin',
    ],
  },
  {
    path: 'mobile-kiosk',
    redirectPath: null,
    children() {
      return <MobileKiosk />;
    },
    permissions: ['kioskModeMobile'],
  },
  {
    path: '/kiosk',
    redirectPath: null,
    children() {
      return <KioskPage />;
    },
    permissions: [],
    isWithoutHeader: 'yes',
  },
  {
    path: 'availability',
    redirectPath: null,
    children() {
      return <AvailabilityWrapper />;
    },
    permissions: [
      'availabilityRead',
      'availabilityFullAccess',
      'availabilityEquipmentEdit',
      'availabilityPeopleEdit',
    ],
  },
  {
    path: 'availability/print/:date',
    redirectPath: null,
    children() {
      return <AvailabilityPrintWrapper />;
    },
    permissions: [
      'availabilityRead',
      'availabilityFullAccess',
      'availabilityEquipmentEdit',
      'availabilityPeopleEdit',
    ],
    isWithoutHeader: 'yes',
  },
  {
    path: 'cip-hours',
    redirectPath: null,
    children() {
      return <Cip />;
    },
    permissions: ['cipFullAccess'],
  },
  {
    path: 'cip-projects',
    redirectPath: null,
    children() {
      return <Cip />;
    },
    permissions: ['cipProjectsFullAccess', 'cipFullAccess'],
  },
  {
    path: 'equipment',
    redirectPath: null,
    children() {
      return <EquipmentPage />;
    },
    permissions: ['equipmentRead', 'equipmentFullAccess'],
  },
  {
    path: 'users',
    redirectPath: null,
    children() {
      return <PeoplePage />;
    },
    permissions: ['usersBasicRead', 'usersFullAccess'],
  },
  {
    path: 'settings',
    redirectPath: null,
    children() {
      return <SettingsPage />;
    },
    permissions: ['settings'],
  },
  {
    path: 'supplies',
    redirectPath: null,
    children() {
      return <Supplies />;
    },
    permissions: ['suppliesFullAccess', 'suppliesEdit'],
  },
  {
    path: 'timeline',
    redirectPath: null,
    children() {
      return <p>timeline</p>;
    },
    permissions: ['timelineRead', 'timelineFullAccess'],
  },
  {
    path: 'timeclock',
    redirectPath: null,
    children() {
      return <TimeClock />;
    },
    permissions: ['timeClockRead', 'timeClockFullAccess'],
  },
  {
    path: 'timeclock/:type',
    redirectPath: null,
    children() {
      return <TimeClock />;
    },
    permissions: ['timeClockRead', 'timeClockFullAccess'],
  },
  {
    path: 'timeclock/:type/:sheetId',
    redirectPath: null,
    children() {
      return <TimeClock />;
    },
    permissions: ['timeClockRead', 'timeClockFullAccess'],
  },
  {
    path: 'schedule',
    redirectPath: null,
    children() {
      return <SchedulePage />;
    },
    permissions: ['scheduleRead', 'scheduleFullAccess'],
  },
  {
    path: 'schedule/:sheetId',
    redirectPath: null,
    children() {
      return <SchedulePage />;
    },
    permissions: ['scheduleRead', 'scheduleFullAccess'],
  },
  {
    path: 'worklog/*',
    redirectPath: null,
    children() {
      return <Worklogs />;
    },
    permissions: ['worklogsRead', 'worklogsFullAccess', 'worklogsEdit'],
  },
  {
    path: 'worklog/:type/:sheetId',
    redirectPath: null,
    children() {
      return <Worklogs />;
    },
    permissions: ['worklogsRead', 'worklogsFullAccess', 'worklogsEdit'],
  },
  {
    path: 'worklog/:type',
    redirectPath: null,
    children() {
      return <Worklogs />;
    },
    permissions: ['worklogsRead', 'worklogsFullAccess', 'worklogsEdit'],
  },
  {
    path: 'admin-panel',
    redirectPath: null,
    children: () => <SuperAdmin />,
    role: ['Super Admin'],
  },
  {
    path: 'submission/:submissionId/pdf-report',
    redirectPath: null,
    children: () => <WorkLogPdf />,
    permissions: [],
    isWithoutHeader: true,
    isPublic: true,
  }
];

export default routes;
