import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const WorkLogPdf = () => {
  useEffect(() => {
    document.title = 'Work Log Pdf';
  }, []);

  const { submissionId } = useParams();

  const source = useMemo(() => (
    submissionId
      ? `${process.env.REACT_APP_BASE_URL}/submissions/${submissionId}/pdf-report`
      : null
  ), [submissionId])

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      {source && <iframe
        src={source}
        width="100%"
        height="100%"
        title="Work Log Pdf"
      />}
    </div>
  );
};

export default WorkLogPdf;