import { useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import { checkUserPermissions } from 'helpers/_helpers';
import { SnackbarProvider } from 'notistack';
import store from 'store/store';

const RequireAuth = ({
  routePermissions = [],
  routeRole = '',
  fullUserPermissions,
  authorized,
  redirectPath = '/signin',
  children,
  isWithoutHeader,
  isPublic,
}) => {
  const { _, getState } = store;
  const { user } = getState().personalProfile;
  const location = useLocation()
  const isItKioskPage = location.pathname === '/kiosk';
  const isKioskModeActive = !!+localStorage.getItem('isKioskPage')

  if (
    (!authorized && !isItKioskPage) ||
    (isKioskModeActive && !isItKioskPage) ||
    (!authorized && isItKioskPage && !isKioskModeActive) ||
    (!authorized && !isPublic)
  ) {
    //* redirect to signin
    return <Navigate to={isKioskModeActive ? '/not-found' : redirectPath} replace />;
  }

  const validRole = routeRole?.length ? routeRole.includes(user?.profile?.role?.roleName) : false

  if (
    fullUserPermissions &&
    !checkUserPermissions(routePermissions, fullUserPermissions) &&
    !validRole &&
    !isKioskModeActive &&
    !isPublic
  ) {
    //* redirect to not found
    return <Navigate to={'/not-found'} replace />;
  }

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={1500}
      >
        {(user || (!user && isKioskModeActive)) && (
          <>
            {!isWithoutHeader && <Header />}
            <main style={isItKioskPage || isWithoutHeader ? null : { paddingTop: 56 }}>
              {children}
            </main>
          </>
        )}
      </SnackbarProvider>
    </>
  );
};

export default connect((state) => ({
  authorized: state.login.authorized,
  fullUserPermissions: state.personalProfile.fullUserPermissions,
}))(RequireAuth);
