import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from 'assets/images/close-icon.svg';
import DownloadIcon from 'assets/images/download-icon.svg';
import DocumentIcon from 'assets/images/document-icon.svg';
import DownloadIconGr from 'assets/images/download-icon-grey.svg';
import DocumentIconGr from 'assets/images/document-icon-grey.svg';
import RefreshIcon from 'assets/images/refresh-icon.svg';

import ConfirmDialog from 'components/ConfirmDialog';
import { getProjectName, hasPermissionsFor } from 'helpers/_helpers';
import { agoDate, clockTime } from 'helpers/_date-helpers';
import SignaturePopover from 'components/SignaturePopover';
import styles from './styles';
import { fetchDeleteSubmittedSheet } from 'store/worklogs/worklogsPageOperations';
import { connect } from 'react-redux';
import { resendEmail } from './helpers/apiCalls';
import { dateSettingsFormat, getTimeFormat } from 'common/dateFormatConfig';

class SubmittedSheetCard extends PureComponent {
  state = {
    isModalOpen: false,
    isExpanded: false,
    anchorEl: null,
  };

  handleExpandClick = (e) => {
    e.stopPropagation();
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };
  
  deleteSheet = async() => {
    await this.props.fetchDeleteSubmittedSheet(this.props.sheet.submission._id);
    this.props.openSnackbar('success',' Successfully Deleted!')
  };

  openDeleteModal = (e) => {
    e.stopPropagation();
    this.setState({ isModalOpen: true });
  };

  getSheetOwner = () => {
    return this.props.sheet.submission.username
      ? `by ${this.props.sheet.submission.username}`
      : null;
  };

  handleOpenPopover = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopover = (e) => {
    if (e) e.stopPropagation();
    this.setState({ anchorEl: null });
  };

  isAnySignature = () => {
    const { signatures } = this.props.sheet.submission;
    return Boolean(signatures && (signatures.dot || signatures.contractor));
  };

  getEmailFieldMessage = () => {
    // TODO: clarify condition to show spinner
    // <img src='/images/ajax-loader.gif' width='16' height='16' />;
    const { email } = this.props.sheet.submission;
    return email || 'N/A';
  };

  openCSV = (type) => (e) => {
    if (e) e.stopPropagation();
    if (type == 'materials' && !this.materialsCSVIsShow) return null;
    if (type == 'totals' && !this.totalsCSVIsShow) return null;
    const url = `/submissions/${this.props.sheet.submission._id}/csv/${type}`;
    window.open(url, '_blank');
  };

  openPDF = (e) => {
    if (e) e.stopPropagation();
    if (this.props.sheet.submission.certPDF) {
      window.open(
        `${process.env.REACT_APP_BASE_URL}/submissions/${
          this.props.sheet.submission._id
        }/certificate?authToken=${localStorage.getItem('authorization-token')}`,
        '_blank'
      );
    }
  };

  openWorklogPdfReport = (e) => {
    if (e) e.stopPropagation();
    if (this.props.sheet.submission.pdfReportKey) {
      window.open(
        `/submission/${this.props.sheet.submission._id}/pdf-report`
      )
    }
  }

  openDocuSign = (envelopeId) => (e) => {
    e.stopPropagation();
    const url = `${process.env.REACT_APP_BASE_URL}/submissions/${this.props.sheet.submission._id}/docusign-certificate/${envelopeId}`;
    window.open(url, '_blank');
  };

  materialsCSVIsShow = this.props.sheet.submission?.materialsCSV.split('\n').length > 1;
  totalsCSVIsShow = this.props.sheet.submission?.totalsCSV.split('\n').length > 1;

  openWorklogDetails = () => {
    this.props.navigate(`/worklog/submissions/${this.props.sheet._id}`);
  };

  hasFullAccess = () => hasPermissionsFor('worklogsFullAccess');

  render() {
    const { classes, sheet } = this.props;
    const { submission } = sheet;
    const { isModalOpen, isExpanded, anchorEl } = this.state;

    return (
      <>
        <Card className={classes.card} onClick={this.openWorklogDetails}>
          <Grid container className={classes.paddingBox}>
            <Grid item xs={12} className={classes.paddingBottom}>
              <div className={classnames(classes.inlineFlex, classes.flexHeader)}>
                <Typography variant="h6" color="textPrimary" className={classes.fixedWidth}>
                  {sheet ? getProjectName(sheet.project) : ''}
                </Typography>
                {this.hasFullAccess() && (
                  <Button
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={this.openDeleteModal}
                    disableRipple
                  >
                    <img src={CloseIcon} alt="delete" />
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.regularFontWeight}
              >
                {sheet
                  ? `Created on ${
                      sheet.createdAtDate
                        ? agoDate(
                            sheet.createdAtDate,
                            null,
                            dateSettingsFormat(this.props.dateFormat, this.props.timeFormat)
                              .dayDateSettingsFormat
                          )
                        : agoDate(
                            sheet.createdAt,
                            null,
                            dateSettingsFormat(this.props.dateFormat, this.props.timeFormat)
                              .dayDateSettingsFormat
                          )
                    }`
                  : null}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.paddingBottom}>
              <Divider />
            </Grid>

            <Grid item xs={12} md={9} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Submitted:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {agoDate(
                  submission.startedAt,
                  null,
                  dateSettingsFormat(this.props.dateFormat, this.props.timeFormat)
                    .dayDateSettingsFormat
                )}{' '}
                at{' '}
                {clockTime(
                  submission.startedAt,
                  getTimeFormat([], this.props.timeFormat, this.props.dateFormat).formatForTimePars
                )}
              </Typography>
              {this.getSheetOwner() && (
                <Typography variant="body1" color="textPrimary">
                  {this.getSheetOwner()}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              className={classnames(classes.paddingBottom, classes.paddingLeft)}
            >
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Email:
              </Typography>
              <Typography variant="body1" color="textPrimary" className={classes.inlineFlex}>
                <span className={classes.capitalize}>{this.getEmailFieldMessage()}</span>
                {this.getEmailFieldMessage() !== 'N/A' && (
                  <IconButton
                    className={classes.iconButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      resendEmail(submission._id, this.props.openSnackbar);
                    }}
                  >
                    <img src={RefreshIcon} alt="refresh" />
                  </IconButton>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Type:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {submission.type}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.paddingBottom}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classnames(classes.regularFontWeight, classes.paddingBottomSmall)}
              >
                Signature:
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                onClick={this.isAnySignature() ? this.handleOpenPopover : null}
                className={classnames(this.isAnySignature() && classes.linkText)}
              >
                {this.isAnySignature() ? 'Show' : 'N/A'}
              </Typography>
              <SignaturePopover
                anchorEl={anchorEl}
                handleClosePopover={this.handleClosePopover}
                signatures={submission.signatures}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={classnames(classes.paddingBox, classes.paddingTop, classes.borderTop)}
                >
                  <div
                    className={this.materialsCSVIsShow ? classes.linkWrapper : classes.disabledIcon}
                    onClick={this.openCSV('materials')}
                  >
                    <Typography variant="body1" color="textPrimary" className={classes.inlineFlex}>
                      <span>
                        <IconButton className={classes.iconButton} disableRipple>
                          <img src={DocumentIcon} />
                        </IconButton>
                        <span className={classes.marginLeft}>Materials CSV</span>
                      </span>
                      <IconButton className={classes.iconButton} disableRipple>
                        <img src={DownloadIcon} alt="download" />
                      </IconButton>
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} className={classnames(classes.paddingBox)}>
                  <div
                    className={this.totalsCSVIsShow ? classes.linkWrapper : classes.disabledIcon}
                    onClick={this.openCSV('totals')}
                  >
                    <Typography variant="body1" color="textPrimary" className={classes.inlineFlex}>
                      <span>
                        <IconButton className={classes.iconButton} disableRipple>
                          <img src={DocumentIcon} />
                        </IconButton>
                        <span className={classes.marginLeft}>Totals CSV</span>
                      </span>
                      <IconButton className={classes.iconButton} disableRipple>
                        <img src={DownloadIcon} alt="download" />
                      </IconButton>
                    </Typography>
                  </div>
                </Grid>
                {Boolean(!submission.certPDF || submission.certPDF !== 'N/A') && (
                  <Grid item xs={12} className={classnames(classes.paddingBox)}>
                    {submission.certPDF === 'ERROR' && (
                      <div className={classes.disabledLink}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.inlineFlex}
                        >
                          <span>
                            <IconButton
                              className={classnames(classes.iconButton, classes.defaultCursor)}
                              disableRipple
                            >
                              <img src={DocumentIconGr} />
                            </IconButton>
                            <span className={classes.marginLeft}>Certificate PDF</span>
                          </span>
                          <IconButton
                            className={classnames(classes.iconButton, classes.defaultCursor)}
                            disableRipple
                          >
                            <img src={DownloadIconGr} alt="download" />
                          </IconButton>
                        </Typography>
                      </div>
                    )}
                    {Boolean(submission.certPDF && submission.certPDF !== 'ERROR') && (
                      <div className={classes.linkWrapper} onClick={this.openPDF}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.inlineFlex}
                        >
                          <span>
                            <IconButton className={classes.iconButton} disableRipple>
                              <img src={DocumentIcon} alt="document" />
                            </IconButton>
                            <span className={classes.marginLeft}>Certificate PDF</span>
                          </span>
                          <IconButton className={classes.iconButton} disableRipple>
                            <img src={DownloadIcon} alt="download" />
                          </IconButton>
                        </Typography>
                      </div>
                    )}
                  </Grid>
                )}
                {submission?.docuSign?.envelopeId
                  ? submission?.docuSign?.status === 'completed' && (
                      <Grid item xs={12} className={classnames(classes.paddingBox)}>
                        <div
                          className={classes.linkWrapper}
                          onClick={this.openDocuSign(submission.docuSign.envelopeId)}
                        >
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            className={classes.inlineFlex}
                          >
                            <span>
                              <IconButton className={classes.iconButton} disableRipple>
                                <img src={DocumentIcon} />
                              </IconButton>
                              <span className={classes.marginLeft}>DocuSign Certificate</span>
                            </span>
                            <IconButton className={classes.iconButton} disableRipple>
                              <img src={DownloadIcon} alt="download" />
                            </IconButton>
                          </Typography>
                        </div>
                      </Grid>
                    )
                  : submission?.docuSign?.envelopes?.filter(
                      (envelope) => envelope.status === 'completed'
                    )?.length
                  ? submission.docuSign.envelopes
                      .filter((envelope) => envelope.status === 'completed')
                      .map((envelope) => (
                        <Grid item xs={12} className={classnames(classes.paddingBox)}>
                          <div
                            className={classes.linkWrapper}
                            onClick={this.openDocuSign(envelope.envelopeId)}
                          >
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              className={classes.inlineFlex}
                            >
                              <span>
                                <IconButton className={classes.iconButton} disableRipple>
                                  <img src={DocumentIcon} />
                                </IconButton>
                                <span className={classes.marginLeft}>
                                  DocuSign Certificate
                                  {envelope.material ? ` - ${envelope.material}` : ''}
                                </span>
                              </span>
                              <IconButton className={classes.iconButton} disableRipple>
                                <img src={DownloadIcon} alt="download" />
                              </IconButton>
                            </Typography>
                          </div>
                        </Grid>
                      ))
                  : null}
                {Boolean(submission.pdfReportKey) && (
                  <Grid item xs={12} className={classnames(classes.paddingBox)}>
                    {/^error/.test(submission.pdfReportKey) && (
                      <div className={classes.disabledLink}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.inlineFlex}
                        >
                          <span>
                            <IconButton
                              className={classnames(classes.iconButton, classes.defaultCursor)}
                              disableRipple
                            >
                              <img src={DocumentIconGr} />
                            </IconButton>
                            <span className={classes.marginLeft}>Work Log PDF</span>
                          </span>
                          <IconButton
                            className={classnames(classes.iconButton, classes.defaultCursor)}
                            disableRipple
                          >
                            <img src={DownloadIconGr} alt="download" />
                          </IconButton>
                        </Typography>
                      </div>
                    )}
                    {Boolean(submission.pdfReportKey) && !/^error/.test(submission.pdfReportKey) && (
                      <div className={classes.linkWrapper} onClick={this.openWorklogPdfReport}>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.inlineFlex}
                        >
                          <span>
                            <IconButton className={classes.iconButton} disableRipple>
                              <img src={DocumentIcon} alt="document" />
                            </IconButton>
                            <span className={classes.marginLeft}>Work Log PDF</span>
                          </span>
                          <IconButton className={classes.iconButton} disableRipple>
                            <img src={DownloadIcon} alt="download" />
                          </IconButton>
                        </Typography>
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </Collapse>
            <CardActions
              className={classes.actionsWrapper}
              disableSpacing
              onClick={this.handleExpandClick}
            >
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: isExpanded,
                })}
                aria-expanded={isExpanded}
                aria-label="Show more"
                disableRipple
                disableFocusRipple
              >
                <ExpandMoreIcon />
              </IconButton>
              <Typography color="textSecondary">
                {isExpanded ? 'Hide work log documents' : 'Show work log documents'}
              </Typography>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: isExpanded,
                })}
                aria-expanded={isExpanded}
                aria-label="Show more"
                disableRipple
                disableFocusRipple
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          </Grid>
        </Card>

        {isModalOpen && (
          <ConfirmDialog
            isOpen={isModalOpen}
            onClose={this.closeModal}
            onSubmit={this.deleteSheet}
            text="Are you sure you want to delete this submission? This action cannot be undone."
            disableEscape
            loadingOnSubmit
          />
        )}
      </>
    );
  }
}

SubmittedSheetCard.propTypes = {
  classes: propTypes.object.isRequired,
  openSnackbar: propTypes.func.isRequired,
  setForceUpdate: propTypes.func.isRequired,
  // submission: propTypes.object.isRequired,
  sheet: propTypes.object.isRequired,
};

// const mapDispatchToProps = { fetchDeleteSubmittedSheet };

export default connect(
  (state) => ({
    dateFormat: state.personalProfile.organization?.settings?.dateFormat,
    timeFormat: state.personalProfile.organization?.settings?.timeFormat,
  }),
  { fetchDeleteSubmittedSheet }
)(withStyles(styles)(SubmittedSheetCard));
